
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
    RadioGroup,
    VStack,
    Radio
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }

const EventInfo = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
         <Box mb="8">
                <FormControl>
                    <FormLabel as="legend">
                        Location<RequiredSpan />
                    </FormLabel>
                    <RadioGroup>
                        <VStack align="left">
                            <Radio value="City Property or Facility" name="cityLocation" {...register("cityLocation")}>City Property or Facility</Radio>
                            <Radio value="Other" name="cityLocation" {...register("cityLocation")}>Other</Radio>
                        </VStack>
                    </RadioGroup>
                    <p style={errorStyles}>{errors.cityLocation && (<HStack spacing="5px"><VscWarning /> <Box> {errors.cityLocation?.message}</Box> </HStack>) }</p>
                </FormControl>
            </Box>

              <Box my="8">
                <FormLabel>Event Name<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("eventName")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.eventName && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.eventName?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Flex w="100%" flexWrap="wrap">
                <Box my="8" w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Date(s) and Time(s) of Event<RequiredSpan /></FormLabel>
                  <Input
                    type="date"
                    {...register("dateEvent")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.dateEvent && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.dateEvent?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box
                  mt="16"
                  mb="0"
                  pl={{ base: "0", md: 4 }}
                  w={{ base: "100%", md: "50%" }}
                >
                  <Input
                    type="time"
                    {...register("timeEvent")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.timeEvent && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.timeEvent?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mt="0" mb="8">
                <FormLabel>Event Organization/Business Name<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("eventOrganizationName")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.eventOrganizationName && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.eventOrganizationName?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mt="0" mb="8">
                <FormLabel>Applicant's Name<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("applicantsName")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.applicantsName && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.applicantsName?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Flex w="100%" flexWrap="wrap">
                <Box mb="8" w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Phone:<RequiredSpan /></FormLabel>
                  <Input type="tel" {...register("phone")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.phone && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.phone?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box
                  mb="8"
                  pl={{ base: "0", md: 4 }}
                  w={{ base: "100%", md: "50%" }}
                >
                  <FormLabel>Email<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mt="0" mb="8">
                <FormLabel>Mailing Address<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("mailingAddress")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.mailingAddress && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.mailingAddress?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>
        </>
    )
}

export default EventInfo;