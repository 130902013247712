import React, { useState, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Box,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
} from "@chakra-ui/react";

import { BsCheck2Circle } from "react-icons/bs";

import EventInfo from "./Sections/EventInfo";
import EventDescription from "./Sections/EventDescription";
import SitePlanInformation from "./Sections/SitePlanInformation";
import AdditionalPermits from "./Sections/AdditionalPermits";
import ReviewProcess from "./Sections/ReviewProcess";
import ApplicationAcceptance from "./Sections/ApplicationAcceptance";

const schema = yup.object().shape({
  cityLocation: yup.string().required("Location is required").nullable(),
  eventName: yup.string().required("Event name is required"),
  dateEvent: yup.date().typeError("Must be a date").required("Date is required"),
  timeEvent: yup.string().required("Time is required"),
  eventOrganizationName: yup.string().required("Organization name is required"),
  applicantsName: yup.string().required("Applicant's name is required"),
  location: yup.string().required("Location is required"),
  alternateLocation: yup.string().required("Alternate location is required"),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  phone: yup
    .string()
    .required()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Invalid phone number"
    ),
  mailingAddress: yup.string().required("Mailing address is required"),

  applicantSignature: yup
    .string()
    .required("Applicant Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  coordinatorSignature: yup
    .string()
    .required("Coordinator Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  propertyOwner: yup.string().required("Property owner is required"),
  propertyOwnerProof: yup.mixed()
  .test('fileSize', 'Max file size 2 MB', (value, context) => {
      if (!value || !value.length) {
          return true;
      } else {
      return value && value[0] && value[0].size <= 2000000;
      }
  }),
  sitePlan: yup.mixed()
  .test('fileSize', 'Max file size 2 MB', (value, context) => {
      if (!value || !value.length) {
          return true;
      } else {
      return value && value[0] && value[0].size <= 2000000;
      }
  }),
  activities: yup.string().required("Activities are required"),
  expectedNumberOfParticipants: yup.string().required("Number of participants is required"),
  expectedNumberOfSpectators: yup.string().required("Number of spectators is required"),
  willTheEventBeTicketed: yup.string().required("Ticketing is required").nullable(),
  contingencyPlan: yup.string().required("Contingency plan is required"),
  theEventRequestingAnyStreetClosure: yup.string().required("Street closure is required").nullable(),
  lawEnforcementServices: yup.string().required("Law enforcement services is required").nullable(),
  eventRequestingAdditionalServices: yup.string().required("Additional services is required").nullable(),

  alcoholAtTheEvent: yup.string().required("Alcohol is required").nullable(),
  anythingBeSoldAtTheEvent: yup.string().required("Anything be sold is required").nullable(),
  foodBeCooked: yup.string().required("Food be cooked is required").nullable(),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

const AppSpecialEventPermit = () => {
  const defaultValues = {
    imgUrl: "",
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
    });

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const watchData = methods.watch();

  console.log(watchData);

  // create a Ref to access our form element
  const formRef = useRef(null);

  const FormSuccess = () => (
    <Box my="20" >
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p="4"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='lgmd'>
        Thank you for submitting your application. We will be in touch with you shortly.<br /><br />Please check your email for a copy of your application for your records.
  </AlertDescription>
    </Alert>
  </Box>
   )

   const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);
    
    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");
    const coordinatorSignatureBlob = await (await fetch(watchData.coordinatorSignature)).blob([coordinatorSignatureBlob], "coordinatorSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");
    formData.append("coordinatorSignature", coordinatorSignatureBlob, "coordinatorSignature.png");
    


    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-special-event-permit', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    methods.reset()
                    scrollTop()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};


  

  return (
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>
            <Box my="8">
              <Text p="4" display="inline-block">
                This form is required for public and private events such as
                outdoor gatherings, parades, carnivals, fairs, outdoor concerts,
                or races that occur infrequently and occur in a facility or on a
                property that is beyond the scope of normal operation. Events
                that anticipate 50+ people, 2+ vendors, or could cause a public
                impact via disturbance, crowd, traffic/parking or disruption of
                the normal routine of the community or affected neighborhood are
                also subject to this permit unless exempted (Ordinance 379-17).
              </Text>
            </Box>
            <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendMailgunFormData)} ref={formRef}>
            
            <EventInfo />
            <EventDescription />
            <SitePlanInformation />
            <AdditionalPermits />
            <ReviewProcess />
            <ApplicationAcceptance />


              <Box my="8">
                <Button type="submit" textAlign="left">
                  {processing ? (
                    <Spinner />
                  ) : succeeded ? (
                    "Submitted!"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
            </FormProvider>
          </>
        )}
      </Box>
  );
};

export default AppSpecialEventPermit;
