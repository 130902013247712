
import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
    Text,
    Button,
    Icon
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

import { BsXCircle } from 'react-icons/bs'
import SignaturePad from 'react-signature-canvas'

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }



const ApplicationAcceptance = () => {
    const defaultValues = {}; 
	{
		/* Signature Pad */
	}
	let applicantSignatureCanvas = useRef({});
	let coordinatorSignatureCanvas = useRef({});

	// formatIntoPng

	const formatApplicantSignatureIntoPng = () => {
		if (applicantSignatureCanvas.current) {
			const dataURL = applicantSignatureCanvas.current.toDataURL();
			return dataURL;
		}
	};

	const formatCoordinatorSignatureIntoPng = () => {
		if (coordinatorSignatureCanvas.current) {
			const dataURL = coordinatorSignatureCanvas.current.toDataURL();
			return dataURL;
		}
	};

	const clearApplicantSignatureCanvas = () =>
    applicantSignatureCanvas.current.clear();
	
    const clearCoordinatorSignatureCanvas = () => coordinatorSignatureCanvas.current.clear();

    const { register, control, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<Box my="8">
                <SectionHeading>
                    Application Acceptance
                </SectionHeading>
              </Box>

              <Box mb="8">
                <Text mb="4">
                  By signing the application below, I agree to indemnify, defend
                  and hold harmless the city, its officers, agents and employees
                  from any and all demands, claims or liability of any nature
                  caused by or arising out of or connected with the permitted
                  special event.
                </Text>

                <Text>
                  I further understand that I am responsible to comply with all
                  of the terms and conditions of this Permit including the
                  content of the application, city ordinance 379-17 and other
                  applicable state and local laws. This permit is
                  non-transferable and may be revoked if I refuse or fail to
                  comply with the Permit and conditions.
                </Text>
              </Box>

              <Flex>
                <Box
                  w={{ base: "100%", md: "50%" }}
                  pr={{ base: "0", md: "6" }}
                >
                  <FormControl
                    isInvalid={!!errors?.applicantSignature?.message}
                    errorText={errors?.applicantSignature?.message}
                    isRequired
                  >
                    <FormLabel>
                      Property Owner (or attach proof of authorization above)
                    </FormLabel>
                    <Box>
                      <Controller
                        name="applicantSignature"
                        control={control}
                        render={({ field }) => (
                          <SignaturePad
                            ref={applicantSignatureCanvas}
                            onEnd={() =>
                              field.onChange(
                                formatApplicantSignatureIntoPng(applicantSignatureCanvas.current.toDataURL())
                              )
                            }
                            penColor="black"
                            canvasProps={{
                              style: {
                                border: "1px solid #e2e8f0",
                                borderRadius: "0.375rem",
                                width: "100%",
                                height: "200px",
                                backgroundColor: "white",
                              },
                            }}
                          />
                        )}
                      />
                      <p style={errorStyles}>
                        {errors.applicantSignature && (
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                          </HStack>
                        )}
                      </p>
                    </Box>
                 </FormControl>
                  <Box my="2" textAlign="right">
                    <Button size="sm" onClick={clearApplicantSignatureCanvas} colorScheme="red">
                      <Icon as={BsXCircle} mr="2" />
                      Clear Signature
                    </Button>
                  </Box>
                </Box>

                <Box
                  w={{ base: "100%", md: "50%" }}
                  pr={{ base: "0", md: "6" }}
                >
                  <FormControl
                    isInvalid={!!errors?.coordinatorSignature?.message}
                    errorText={errors?.coordinatorSignature?.message}
                    isRequired
                  >
                    <FormLabel>
                      Property Owner (or attach proof of authorization above)
                    </FormLabel>
                    <Box>
                      <Controller
                        name="coordinatorSignature"
                        control={control}
                        render={({ field }) => (
                          <SignaturePad
                            ref={coordinatorSignatureCanvas}
                            onEnd={() =>
                              field.onChange(
                                formatCoordinatorSignatureIntoPng(coordinatorSignatureCanvas.current.toDataURL())
                              )
                            }
                            penColor="black"
                            canvasProps={{
                              style: {
                                border: "1px solid #e2e8f0",
                                borderRadius: "0.375rem",
                                width: "100%",
                                height: "200px",
                                backgroundColor: "white",
                              },
                            }}
                          />
                        )}
                      />
                      <p style={errorStyles}>
                        {errors.coordinatorSignature && (
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.coordinatorSignature?.message}</Box>
                          </HStack>
                        )}
                      </p>
                    </Box>
                 </FormControl>
                  <Box my="2" textAlign="right">
                    <Button size="sm" onClick={clearCoordinatorSignatureCanvas} colorScheme="red">
                      <Icon as={BsXCircle} mr="2" />
                      Clear Signature
                    </Button>
                  </Box>
                </Box>
              </Flex>
        </>
    )
}

export default ApplicationAcceptance;