
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    HStack,
	Heading,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    Text,
    UnorderedList,
    ListItem,
    Link,
    Textarea,
    VStack
} from '@chakra-ui/react'

import { ExternalLinkIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import { VscWarning } from 'react-icons/vsc';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;



const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }

const AdditionalPermits = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<Box my="8" >
                <SectionHeading>
                  Additional Permits
                </SectionHeading>
              </Box>
                <Box mb="4">
                  <FormControl>
                    <RadioGroup>
                      <VStack align="left" justify="left">
                        <FormLabel mb="0">
                        <Text as="b">
                          Beer &amp; Wine Permit or Catering Permit:
                        </Text><br />
                          Will there be alcohol at the event?
                        </FormLabel>
                        <Radio
                          value="Yes"
                          name="alcoholAtTheEvent"
                          {...register("alcoholAtTheEvent")}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          name="alcoholAtTheEvent"
                          {...register("alcoholAtTheEvent")}
                        >
                          No
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </Box>

              {watchData.alcoholAtTheEvent === "Yes" && (
                <Box mb="4">
                  <FormControl>
                    <RadioGroup>
                      <HStack spacing="18px" align="left">
                        <Radio
                          value="Yes"
                          name="willAlchoholBeSold"
                          {...register("willAlchoholBeSold")}
                        >
                          If <Text as="b">Yes</Text>, will alcohol be{" "}
                          <Text as="b">sold</Text>
                        </Radio>
                        <Radio
                          value="No"
                          name="willAlchoholBeSold"
                          {...register("willAlchoholBeSold")}
                        >
                          or given away
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}

              {watchData.willAlchoholBeSold === "Yes" && (
                <Box>
                  <FormControl mb="4">
                    <FormLabel as="legend">
                      If sold, who is responsible for the sales?{" "}
                      <Text as="small">
                        (Individual, organization, or business)
                      </Text>
                    </FormLabel>
                    <Textarea
                      {...register("responsibleForTheSales")}
                      autoComplete="on"
                    ></Textarea>
                  </FormControl>
                  <UnorderedList>
                    <ListItem mb="2">
                      Contact the City Clerk for a{" "}
                      <Link
                        href="http://www.driggs.govoffice.com/vertical/sites/%7B8B90FF44-8C7B-4EE4-A523-9BC2CBB6A131%7D/uploads/Charitable_Event_Beer__Wine.pdf"
                        isExternal
                      >
                        temporary beer & wine license{" "}
                        <ExternalLinkIcon mx="2px" />
                      </Link>{" "}
                      if being sold. State, County &amp; Driggs City Council
                      approval may be required (<Link href="mailto:kschuehler@driggsidaho.org" isExternal>
                        kschuehler@driggsidaho.org
                      </Link>{" "}
                      or{" "}
                      <Link href="tel:2083542362" isExternal>
                        208-354-2362
                      </Link>
                      ).
                    </ListItem>
                    <ListItem>
                      How will you ensure all applicable dispensing laws are
                      abided by?
                      <FormControl mt="2">
                        <Textarea
                          {...register("applicableLaws")}
                          autoComplete="on"
                        ></Textarea>
                      </FormControl>
                    </ListItem>
                  </UnorderedList>
                </Box>
              )}

                <Box mb="4">
                  <FormControl mb="4">
                    <RadioGroup>
                      <VStack align="left" justify="left">
                        <FormLabel mb="0">
                        <Text as="b">Retail sales:</Text><br />
                          Will anything be sold at the event?
                        </FormLabel>
                        <Radio
                          value="Yes"
                          name="anythingBeSoldAtTheEvent"
                          {...register("anythingBeSoldAtTheEvent")}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          name="anythingBeSoldAtTheEvent"
                          {...register("anythingBeSoldAtTheEvent")}
                        >
                          No
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                  
                  {watchData.anythingBeSoldAtTheEvent === "Yes" && (
                      <>
                        <Text mb="4">
                    If Yes, complete a list of vendors describing the
                    sale activity (ex. food, products, services, etc.)
                  </Text>

                  <UnorderedList>
                    <ListItem>
                      If retail sales will take place, you must collect the 0.5%
                      Driggs Sales Tax. This Sales Tax will be required to be
                      submitted to the city after the event. Check with the
                      State of Idaho regarding state sales tax requirements.
                      <FormControl mt="2">
                        <Textarea
                          {...register("retailSalesTextArea")}
                          autoComplete="on"
                        ></Textarea>
                      </FormControl>
                    </ListItem>
                  </UnorderedList>
                      </>
                  )}
                  
                </Box>

                <Box mb="4">
                  <FormControl mb="4">
                    <RadioGroup>
                      <VStack align="left" justify="left">
                        <FormLabel mb="0">
                            <Text as="b">
                            Food Preparation &amp; Sales:</Text><br />
                          Will food be cooked and/or sold?
                        </FormLabel>
                        <Radio
                          value="Yes"
                          name="foodBeCooked"
                          {...register("foodBeCooked")}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          name="foodBeCooked"
                          {...register("foodBeCooked")}
                        >
                          No
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>

                  {watchData.foodBeCooked === "Yes" && (
                      <Text mb="4">
                      If <Text as="b">Yes</Text>, a Permit from the Eastern Idaho
                      Public Health District may be required.{" "}
                      <Link href="www.phd7.idaho.gov " isExternal>
                        www.phd7.idaho.gov <ExternalLinkIcon mx="2px" />
                      </Link>{" "}
                      or{" "}
                      <Link href="tel:2083542220 " isExternal>
                        (208) 354-2220. <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Text>
                  )}
                  
                </Box>
  
              <Box my="8" w={{ base: "100%", md: "100%" }}>
                <FormControl mb="4">
                  <CheckboxGroup>
                    <HStack spacing="10px" align="center" justify="left">
                      <Checkbox
                        value="other"
                        name="otherPermission"
                        {...register("otherPermission")}
                      >
                        <Text as="b">Other:</Text> Permission and/or permits may
                        also be required from other State or local
                        organizations, such as Idaho Department of
                        Transportation, Teton County, and Teton County Fire
                        District depending on the scope of the event.
                      </Checkbox>
                    </HStack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
        </>
    )
}

export default AdditionalPermits;