
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    HStack,
    Flex,
	Heading,
    RadioGroup,
    OrderedList,
    Radio,
    ListItem,
    Textarea,
    Text
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }

const EventDescription = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<Box my="8">
                <SectionHeading>
                  Event Description
                </SectionHeading>
              </Box>

              <Box mb="8">
                <OrderedList>
                  <ListItem mb="8">
                    <Box mb="2">
                      <FormLabel>Location<RequiredSpan /></FormLabel>
                      <Input
                        type="text"
                        {...register("location")}
                        autoComplete="on"
                      />
                      <p style={errorStyles}>
                        {errors.location && (
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.location?.message}</Box>
                          </HStack>
                        )}
                      </p>
                    </Box>
                    <Box>
                      <FormLabel>Alternate Location<RequiredSpan /></FormLabel>
                      <Input
                        type="text"
                        {...register("alternateLocation")}
                        autoComplete="on"
                      />
                      <p style={errorStyles}>
                        {errors.alternateLocation && (
                          <HStack spacing="5px">
                            <VscWarning />{" "}
                            <Box>{errors.alternateLocation?.message}</Box>
                          </HStack>
                        )}
                      </p>
                    </Box>
                  </ListItem>
                  <ListItem mb="8">
                    <FormLabel>Property Owner<RequiredSpan /></FormLabel>
                    <Input
                      type="text"
                      {...register("propertyOwner")}
                      autoComplete="on"
                    />
                    <p style={errorStyles}>
                      {errors.propertyOwner && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.propertyOwner?.message}</Box>
                        </HStack>
                      )}
                    </p>
                    
                        <>
                            <Text as="b">
                            Proof of property owner permission submitted if not the same as the applicant.
                            </Text>
                        <FormControl>
                            <FormLabel>
                            Upload proof of property owner permission<br />
                            <small>Max file size 2 MB</small>
                            </FormLabel>
                        <input type="file" name="propertyOwnerProof" {...register("propertyOwnerProof")} />
                        
                        </FormControl>
                        </>
                  </ListItem>
                  <ListItem mb="8">
                    <FormLabel>
                      Activities<RequiredSpan />
                    </FormLabel>
                    <Textarea
                      {...register("activities")}
                      autoComplete="on"
                    ></Textarea>
                    <p style={errorStyles}>
                      {errors.activities && (
                        <HStack spacing="5px">
                          <VscWarning /> <Box>{errors.activities?.message}</Box>
                        </HStack>
                      )}
                    </p>
                  </ListItem>
                  <ListItem mb="8">
                      <Text as="b">
                      Expected number of: 
                      </Text>
                    <Flex w="100%" flexWrap="wrap">
                      <Box w={{ base: "100%", md: "50%" }}>
                        <FormLabel>Participants<RequiredSpan /></FormLabel>
                        <Input
                          type="number"
                          {...register("expectedNumberOfParticipants")}
                          autoComplete="on"
                        />
                        <p style={errorStyles}>
                          {errors.expectedNumberOfParticipants && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {errors.expectedNumberOfParticipants?.message}
                              </Box>
                            </HStack>
                          )}
                        </p>
                      </Box>
                      <Box
                        mb="8"
                        pl={{ base: "0", md: 4 }}
                        w={{ base: "100%", md: "50%" }}
                      >
                        <FormLabel>Spectators<RequiredSpan /></FormLabel>
                        <Input
                          type="number"
                          {...register("expectedNumberOfSpectators")}
                          autoComplete="on"
                        />
                        <p style={errorStyles}>
                          {errors.expectedNumberOfSpectators && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {errors.expectedNumberOfSpectators?.message}
                              </Box>
                            </HStack>
                          )}
                        </p>
                      </Box>
                    </Flex>
                  </ListItem>
                  <ListItem mb="8">
                    <FormControl>
                      <FormLabel as="legend">
                        Will the event be ticketed?<RequiredSpan />
                      </FormLabel>
                      <RadioGroup>
                        <HStack spacing="18px" align="left">
                          <Radio
                            value="yes"
                            name="willTheEventBeTicketed"
                            {...register("willTheEventBeTicketed")}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="no"
                            name="willTheEventBeTicketed"
                            {...register("willTheEventBeTicketed")}
                          >
                            No
                          </Radio>
                        </HStack>
                      </RadioGroup>
                      <p style={errorStyles}>
                          {errors.willTheEventBeTicketed && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {errors.willTheEventBeTicketed?.message}
                              </Box>
                            </HStack>
                          )}
                        </p>
                    </FormControl>
                  </ListItem>
                  <ListItem mb="8">
                    <FormLabel>
                      <Text as="b">Contingency Plan:<RequiredSpan /></Text><br />Please describe a plan for additional
                      resources that may be called upon or used if the
                      participation numbers are greater than expected:
                    </FormLabel>
                    <Textarea
                      {...register("contingencyPlan")}
                      autoComplete="on"
                    ></Textarea>
                    <p style={errorStyles}>
                      {errors.contingencyPlan && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.contingencyPlan?.message}</Box>
                        </HStack>
                      )}
                    </p>
                  </ListItem>
                  <ListItem mb="8">
                    <FormControl>
                      <FormLabel as="legend">
                        Is the event requesting any street closures?<RequiredSpan />
                      </FormLabel>
                      <RadioGroup mb="2">
                        <HStack spacing="18px" align="left">
                          <Radio
                            value="yes"
                            name="theEventRequestingAnyStreetClosure"
                            {...register("theEventRequestingAnyStreetClosure")}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="no"
                            name="theEventRequestingAnyStreetClosure"
                            {...register("theEventRequestingAnyStreetClosure")}
                          >
                            No
                          </Radio>
                        </HStack>
                      </RadioGroup>
                      <p style={errorStyles}>
                      {errors.theEventRequestingAnyStreetClosure && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.theEventRequestingAnyStreetClosure?.message}</Box>
                        </HStack>
                      )}
                    </p>
                    </FormControl>

                    {watchData.theEventRequestingAnyStreetClosure === "yes" && (
                        <Box>
                        <FormLabel>
                          If Yes, please describe the location, activity, and
                          date/time (attach additional sheets as needed for parade
                          routes, etc.):
                        </FormLabel>
                        <Textarea
                          {...register("theEventRequestingAnyStreetClosureText")}
                          autoComplete="on"
                        ></Textarea>
                        <p style={errorStyles}>
                          {errors.theEventRequestingAnyStreetClosureText && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {
                                  errors.theEventRequestingAnyStreetClosureText
                                    ?.message
                                }
                              </Box>
                            </HStack>
                          )}
                        </p>
                      </Box>
                    )}
                    
                  </ListItem>
                  <ListItem mb="8">
                    <FormControl>
                      <FormLabel as="legend">
                        Will the event require law enforcement services beyond
                        routine periodic patrol?<RequiredSpan />
                      </FormLabel>
                      <RadioGroup mb="2">
                        <HStack spacing="18px" align="left">
                          <Radio
                            value="yes"
                            name="lawEnforcementServices"
                            {...register("lawEnforcementServices")}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="no"
                            name="lawEnforcementServices"
                            {...register("lawEnforcementServices")}
                          >
                            No
                          </Radio>
                        </HStack>
                        <p style={errorStyles}>
                          {errors.lawEnforcementServices && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {
                                  errors.lawEnforcementServices?.message
                                }
                              </Box>
                            </HStack>
                          )}
                        </p>
                      </RadioGroup>
                      
                    </FormControl>
                    
                    {watchData.lawEnforcementServices === "yes" && (
                         <Box>
                         <FormLabel>
                           If yes, please describe in detail below:
                         </FormLabel>
                         <Textarea
                           {...register("lawEnforcementServicesText")}
                           autoComplete="on"
                         ></Textarea>
                         <p style={errorStyles}>
                           {errors.lawEnforcementServicesText && (
                             <HStack spacing="5px">
                               <VscWarning />{" "}
                               <Box>
                                 {errors.lawEnforcementServicesText?.message}
                               </Box>
                             </HStack>
                           )}
                         </p>
                       </Box>
                    )}
                   
                  </ListItem>
                  <ListItem mb="8">
                    <FormControl>
                      <FormLabel as="legend">
                        Is the event requesting any additional City of Driggs
                        services?<RequiredSpan /><br />
                        <Text as="small" fontWeight="300">
                          <Text as="i">Additional fees may apply.</Text>
                        </Text>
                      </FormLabel>
                      <RadioGroup mb="2">
                        <HStack spacing="18px" align="left">
                          <Radio
                            value="yes"
                            name="eventRequestingAdditionalServices"
                            {...register("eventRequestingAdditionalServices")}
                          >
                            Yes
                          </Radio>
                          <Radio
                            value="no"
                            name="eventRequestingAdditionalServices"
                            {...register("eventRequestingAdditionalServices")}
                          >
                            No
                          </Radio>
                        </HStack>
                      </RadioGroup>
                      <p style={errorStyles}>
                           {errors.eventRequestingAdditionalServices && (
                             <HStack spacing="5px">
                               <VscWarning />{" "}
                               <Box>
                                 {errors.eventRequestingAdditionalServices?.message}
                               </Box>
                             </HStack>
                           )}
                         </p>
                    </FormControl>
                    
                    {watchData.eventRequestingAdditionalServices === "yes" && (
                        <Box>
                        <FormLabel>
                        If Yes, describe below: (i.e. barricades, traffic signage, snow removal, etc.)
                        </FormLabel>
                        <Textarea
                          {...register("eventRequestingAdditionalServicesText")}
                          autoComplete="on"
                        ></Textarea>
                        <p style={errorStyles}>
                          {errors.eventRequestingAdditionalServicesText && (
                            <HStack spacing="5px">
                              <VscWarning />{" "}
                              <Box>
                                {
                                  errors.eventRequestingAdditionalServicesText
                                    ?.message
                                }
                              </Box>
                            </HStack>
                          )}
                        </p>
                      </Box>
                    )}
                    
                  </ListItem>
                </OrderedList>
              </Box>
        </>
    )
}

export default EventDescription;