
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    Text,
    Link,
  	Heading,
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }

const ReviewProcess = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<Box my="8">
              <SectionHeading>
                Review Process
                </SectionHeading>
              </Box>

              <Box>
                <Text mb="4">
                  The application must be submitted to the Planning Department
                  or the City Clerk if the event will occur on city property.
                  The application and site plan will be routed to service
                  providers and applicable agencies for review and comment.
                  Reasonable conditions may be imposed as the city finds
                  reasonable and necessary for the protection of persons and
                  property and to comply with the approval criteria of the
                  Special Event Permit Ordinance 379-17. Proof of insurance may
                  be required and if the event occurs on city property,
                  insurance listing the city as an additional insured may also
                  be required.
                </Text>

                <Text>
                  Please contact the City of Driggs at{" "}
                  <Link href="tel:2083542362" isExternal>
                    (208) 354-2362 <ExternalLinkIcon mx="2px" />
                  </Link>{" "}
                  if you have questions about what services you may need for
                  your event.
                </Text>
              </Box>
        </>
    )
}

export default ReviewProcess;