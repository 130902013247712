
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormLabel,
    HStack,
	Heading,
    Text,
    Link,
} from '@chakra-ui/react'
import { VscWarning } from 'react-icons/vsc';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';

const SectionHeading = styled(Heading)`
	font-family: 'proxima-nova', sans-serif;
	margin-bottom: 1rem;
	font-size: 1.5rem !important;
`;

const errorStyles = {
	color: "#bf1650",
	fontSize: "1rem",
  };
  
  function RequiredSpan() {
	return <span style={{ color: "#bf1650" }}>*</span>;
  }

const SitePlanInformation = () => {
    const defaultValues = {};

    const { register, formState: { errors } } = useFormContext({
        defaultValues,
    });
   	const watchData = useFormContext().watch();
    //console.log(watchData);
    return (
        <>
		<Box my="8">
                <SectionHeading>
                  Site Plan &amp; Information
                </SectionHeading>
                <Heading as="h4" fontFamily="proxima-nova" fontSize="1.125rem">
                Please attach a Site Plan that address the following items:
                </Heading>
              </Box>

              <Box my="8">
                    <Text as="b">Event Information: </Text> Event schedule
                    including set up and tear down and attach an 8 1⁄2" x 11"
                    drawing detailing the event (location of tents, canopies,
                    fencing, parking, security, vendors, toilets, seating,
                    stage, etc.).
              </Box>

              <Box my="8">
                    <Text as="b">Access/Parking: </Text> Detailed parking plan
                    that illustrates how you will handle vehicle parking and
                    identify public or private roads used to access the site.
                    Include transit and bicycle/pedestrian areas if applicable.
              </Box>

              <Box my="8">
                    <Text as="b">Trash: </Text> All events are required to have
                    a plan for removal of waste and garbage during and after the
                    event as well as accommodate recyclable materials. Attach a
                    detailed Storage Container and Trash Removal Plan including
                    recycling.{" "}
                    <Link href="https://radcurbside.com/" isExternal>
                      RAD Curbside <ExternalLinkIcon mx="2px" />
                    </Link>{" "}
                    is the city's authorized hauler unless self-hauling or
                    otherwise approved.
              </Box>

              <Box my="8">
                    <Text as="b">Recycling: </Text> All events are required to
                    have a plan for the deposit and removal of recycled
                    material. Please include a detailed recycling plan
                    identifying the types and location of recycling bins and
                    removal plan.{" "}
                    <Link href="https://radcurbside.com/" isExternal>
                      RAD Curbside <ExternalLinkIcon mx="2px" />
                    </Link>{" "}
                    is the city's authorized hauler unless self-hauling or
                    otherwise approved.
            
              </Box>

              <Box my="8">
                    <Text as="b">Portable Toilets: </Text> A detailed portable
                    toilet plan for events {"> 200"} people is required. A plan
                    may still be required {"if < 200"} people depending on the
                    location and duration of the event. Show location of toilets
                    on event map/site plan. At least one unit must meet ADA
                    requirements.
        
              </Box>

              <Box my="8">
                    <Text as="b">Signs: </Text> If you are requesting signs to
                    be posted in the right-of-way or oriented to the public
                    outside of the event, you must contact the P&Z Department
                    for temporary sign allowances. Signs that are internal to
                    the event or incidental signs do not need review.
              </Box>
              
              <Box my="8">
                  <FormControl>
                    <FormLabel>
                        Upload Site Plan
                        <RequiredSpan />
                        <br/><small>Max file size 2 MB</small>
                    </FormLabel>
                    <input
                    type="file"
                    id="sitePlan"
                    name="sitePlan"
                    {...register("sitePlan", { required: true })}
                  />
                  <p style={errorStyles}>
                    {errors.sitePlan && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.sitePlan?.message}</Box>
                      </HStack>
                    )}
                  </p>
                  </FormControl>
              </Box>
        </>
    )
}

export default SitePlanInformation;